import React, { useEffect } from 'react';
import { getMenu, navigateToStep } from '../../../utilities/steps';

export const Ready = () => {
  const menu = getMenu();

  useEffect(() => {
    navigateToStep(menu[0]);
  });

  return <div></div>;
};

export default Ready;
