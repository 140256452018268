import { PageWithIntro, WizardLayout } from 'components';
import React, { useEffect } from 'react';
import { Ready } from './ready';
import { initializeState } from '../../../utilities/formstate';
import { resumeStep } from '../../../utilities/stepDefinitions';
import { stopImpersonating } from '../../../utilities/auth';

const ResumePage = () => {
  initializeState();

  useEffect(stopImpersonating(), []);

  return (
    <PageWithIntro step={resumeStep}>
      <WizardLayout text>
        <Ready />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default ResumePage;
